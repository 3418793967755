.footer {
  clear: both;
  position: relative;

  @media (min-width: 640px) {
    margin-left: 240px;
    width: calc(100% - 240px - 2em);
  }
}

.footerFull {
  clear: both;
  position: relative;

  @media (min-width: 640px) {
    margin-left: 240px;
    width: calc(100% - 2em);
  }
}

.links {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  margin: 1em auto;
  justify-content: center;

  @media (min-width: 640px) {
    margin-left: auto;
  }
}