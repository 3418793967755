.tableRow {
  align-items: center;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  max-width: 100%;
  padding: 1em;
  border-radius: 5px;
}

.tableCell { 
  min-width: 200px;
}

.deleteButton {
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  .tableRow {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }  

  .tableCell {
    width: 100%;
  }

  .tableCellInput { 
    width: 100%;
  }
}