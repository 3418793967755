.content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.leagueGrid {
  max-width: 100%;
  width: 20em;
}

.leagueHeaderRow {
  border-bottom: 1px solid black;
  margin-bottom: 0.5em;
  padding: 0.5em;
  text-align: center;
}

.leagueRow {
  display: flex;
  padding: 1em;
  align-items: center;
}

.leagueRow > .link{
  margin-left: auto;
}

.leagueRow.dark {
  background-color: #cccccc30;
}